<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">来源</span>
          <el-select v-model="searchParams.source" placeholder="请选择" clearable size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in sourceList"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
          <!-- <el-input v-model="searchParams.source" size="small" clearable style="width: 400px" placeholder="请输入姓名/手机号码"></el-input> -->
        </div>
        <div class="search-item">
          <span class="label">状态</span>
          <el-select v-model="searchParams.status" placeholder="请选择" clearable size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option label="已处理" :value="true"></el-option>
            <el-option label="未处理" :value="false"></el-option>
          </el-select>
          <!-- <el-input v-model="searchParams.phone" size="small" clearable style="width: 400px" placeholder="请输入姓名/手机号码"></el-input> -->
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="getList">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center flex-between">
        <span class="flex-1">客户线索列表</span>
        <el-button type="primary" size="small" @click="turnTo">配置短信通知</el-button>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          prop="createTime"
          label="提交时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="source"
          label="来源"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.source === 'QR_CODE'">扫码</div>
            <div v-if="scope.row.source === 'WE_CHAT_PUBLIC_ACCOUNT_WANT_BUY'">公众号-我要购买</div>
            <div v-if="scope.row.source === 'WE_CHAT_PUBLIC_ACCOUNT_WANT_RECOMMEND'">公众号-我要推荐</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="city"
          label="城市"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="weddingBrand"
          label="婚礼堂品牌"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="状态"
        >
          <template slot-scope="scope">
          <el-select v-model="scope.row.status" placeholder="请选择" size="small" @change="changeStatus(scope.row)" :class="scope.row.status?'select1':'select2'" style="width: 100px">
            <el-option label="已处理" :value="true"></el-option>
            <el-option label="未处理" :value="false"></el-option>
          </el-select>
            <!-- {{statusMap[scope.row.status]}} -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { get_YhjClueList, post_updateYhjClue } from '@/api/base'
import { get_key_value } from '@/api/common'

export default {
  name: 'marketer-list',
  mixins: [pageMixin],
  components: {
  },
  data() {
    return {
      list: [],
      sourceList: [],
      searchParams: {
        source: '',
        status: ''
      }
    }
  },
  computed: {
  },
  mounted() {
    this.getSourceList()
  },
  methods: {
    getSourceList() {
      get_key_value({
        type: 'YHJ_CLUE_SOURCE'
      }).then(res => {
        // console.log(res,11)
        this.sourceList = res ? res.records : []
        this.getList()
      })
    },
    getList() {
      get_YhjClueList({
        ...this.pageParams,
        ...this.searchParams
      }).then(res => {
        console.log(res)
        this.list = res ? res.records : []
        this.totalItems = res ? res.total : 0
      })
    },
    changeStatus(val) {
      post_updateYhjClue({
        id: val.id,
        status: val.status
      }).then(() => {
        this.$message.success('修改成功')
      })
    },
    turnTo() {
      this.$router.push({ name: 'system-notice'})
    }
  }
}
</script>

<style lang="scss" scoped>
.select1 {
  ::v-deep {
    .el-input {
      .el-input__inner {
        background: #E6F8E6;
        color: #64A264;
        border-width: 0;
      }
      .el-select__caret {
        color: #64A264;
      }
    }
  }
}  
.select2 {
  ::v-deep {
    .el-input {
      .el-input__inner {
        background: #f7eaea;
        color: #ba5454;
        border-width: 0;
      }
      .el-select__caret {
        color: #ba5454;
      }
    }
  }
}  
</style>