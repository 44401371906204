<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">姓名/手机号码</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 400px" placeholder="请输入姓名/手机号码"></el-input>
        </div>
        <div class="search-item">
          <span class="label">等级</span>
          <el-select v-model="searchParams.rank" placeholder="请选择" clearable size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in levelOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">状态</span>
          <el-select v-model="searchParams.status" placeholder="请选择" clearable size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">直销列表</span>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          prop="realName"
          label="姓名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号码"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="rank"
          label="等级"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="状态"
        >
          <template slot-scope="scope">
          <el-select v-model="scope.row.status" placeholder="请选择" size="small" @change="changeStatus(scope.row)" :class="scope.row.status === 1?'select1':'select2'" style="width: 100px">
            <el-option label="启用中" :value="1"></el-option>
            <el-option label="已禁用" :value="2"></el-option>
          </el-select>
            <!-- {{statusMap[scope.row.status]}} -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="goHotelList(scope.row)">查看关联酒店</el-button>
            <!-- <el-popconfirm
              confirm-button-text="确认"
              cancel-button-text="取消"
              :title="scope.row.status === 1?'确定禁用该用户？':'确定启用该用户？'"
              @confirm="scope.row.status === 1?disableSaleUser(scope.row.id):enableSaleUser(scope.row.id)"
            >
              <el-button slot="reference" type="text" style="margin-left: 10px;">{{scope.row.status === 1?'禁用':'启用'}}</el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { InvoiceStatusColor } from '@/config/finance-config'
import { get_key_value } from '@/api/common'
import { post_getSaleUserList, post_disableSaleUser, post_enableSaleUser } from "@/api/product";

export default {
  name: 'marketer-list',
  mixins: [pageMixin],
  components: {
  },
  data() {
    return {
      InvoiceStatusColor,
      levelOption: [],
      statusOption: [],
      leveleMap: {},
      statusMap: {},
      searchParams: {
        search: '',
        rank: '',
        status: ''
      },
    }
  },
  computed: {
  },
  activated() {
    if (this.$store.state.page.keepAlive.indexOf('marketer-list') < 0) {
      Object.assign(this.$data, this.$options.data())
      this.getOption()
      this.getList()
    }
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'SALE_USER_STATUS'
      }).then(res => {
        this.statusOption = res ? res.records : []
        this.statusMap = this.$arrayToMap(this.statusOption)
      })
      get_key_value({
        type: 'SALE_USER_RANK'
      }).then(res => {
      this.levelOption = res ? res.records : []
      this.leveleMap = this.$arrayToMap(this.levelOption)
      })
    },
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      const res = await post_getSaleUserList(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    goHotelList(row) {
      this.$router.push({
        name: 'hotel-list',
        query: {
          marketId: row.userId
        }
      })
    },
    changeStatus(val) {
      // console.log(val,11)
      if(val.status === 1) {
        this.enableSaleUser(val.id)
      } else {
        this.disableSaleUser(val.id)
      }
    },
    enableSaleUser(id) {
      post_enableSaleUser({
        id: id
      }).then(() => {
        this.$message({
          message: "启用成功",
          type: "success",
        });
        this.getList()
      })
    },
    disableSaleUser(id) {
      post_disableSaleUser({
        id: id
      }).then(() => {
        this.$message({
          message: "禁用成功",
          type: "success",
        });
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.select1 {
  ::v-deep {
    .el-input {
      .el-input__inner {
        background: #E6F8E6;
        color: #64A264;
        border-width: 0;
      }
      .el-select__caret {
        color: #64A264;
      }
    }
  }
}  
.select2 {
  ::v-deep {
    .el-input {
      .el-input__inner {
        background: #f7eaea;
        color: #ba5454;
        border-width: 0;
      }
      .el-select__caret {
        color: #ba5454;
      }
    }
  }
}  
</style>